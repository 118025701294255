<template lang="pug">
modal(size='sm' @close='$emit("close")')
  modal-header(:title='$t("Users")' class='!mb-2' @close='$emit("close")')
  div(v-if='project && project.members' class='bleed !mb-4')
    list-item(v-for='({ fullName, email, userId }, index) in project.members' :key='userId' class='leading-tight')
      template(#icon)
        avatar(:username='fullName' :size='32')
      div(class='inline-flex items-center gap-2 font-bold') 
        span {{ fullName }}
        span(class='font-normal text-text-light') ({{ userId }})
      div(class='') {{ email }}
      template(#actions)
        pill(v-if='index === 0') {{ $t('Inhaber') }}

        btn(
          v-if='index > 0 && ($permissions.canAccessAdminMenu || (isCurrentUserProjectMember && project.members.length > 1))'
          plain
          data-test='btn-remove-member'
          icon='trash'
          :data-tooltip='$t("Benutzer entfernen")'
          @click='remove(userId)'
        )
  empty-state(v-else centered)
  form-footer
    template(#secondary)
      btn(icon='plus' secondary @click='$modals.open("form-project-member-add", { id })')
        span {{ $t('Add user from system') }}
</template>

<script setup lang="ts">
import { useProjectsStore } from '../store'
import { type IProjectData, type IProjectMember } from '../types'
import { useConfirm } from '@/plugins/Confirm'
import { useUser } from '@/plugins/User'
import { useTranslation } from '@/plugins/translation'
import { computed } from 'vue'

const isUserProjectMember = (project: IProjectData, userId: string): boolean =>
  project.members.some((member: IProjectMember) => member.userId === userId)

const props = defineProps<{ id: string }>()

const { $t } = useTranslation()
const { confirm } = useConfirm()
const $user = useUser()
const store = useProjectsStore()

const project = computed(() => store.project(props.id))
const isCurrentUserProjectMember = computed(() => isUserProjectMember(project.value, $user.id))

const remove = (userId: string, userEmail?: string) =>
  confirm({
    title: $t('Do you really want to remove {0}?', undefined, [userEmail || userId]),
    okText: $t('Yes'),
    cancelText: $t('Cancel'),
    onOk: () => store.removeMember(props.id, userId),
  })
</script>
